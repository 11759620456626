<template>
  <div v-if="category && section && element">
    <nav
      aria-label="Breadcrumb"
      class="
        flex
        items-center
        text-300 text-sm
        font-medium
        space-x-2
        whitespace-nowrap
      "
    >
      <router-link :to="`/#${category}`" class="hover:text-100">
        {{ toHeading(category) }}
      </router-link>
      <svg
        aria-hidden="true"
        width="24"
        height="24"
        fill="none"
        class="flex-none text-300"
      >
        <path
          d="M10.75 8.75l3.5 3.25-3.5 3.25"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
      </svg>
      <router-link
        :to="`/#${category}-${section}`"
        aria-current="page"
        class="truncate hover:text-100"
      >
        {{ toHeading(section) }}
      </router-link>
    </nav>
    <h1
      class="order-1 text-200 text-3xl font-extrabold tracking-tight mt-2 mb-8"
    >
      {{ toHeading(element) }}
    </h1>

    <div class="flex flex-col space-y-20 pb-10">
      <div v-for="component in components" :key="component.name">
        <component v-show="false" v-bind:is="component.name" />
        <TabGroup>
          <div class="mb-3 flex justify-between items-center">
            <h2
              :id="`${category}-${section}-${component.name}`"
              class="font-medium text-200 truncate"
            >
              <router-link
                :to="`#${category}-${section}-${component.name}`"
                class="mr-1"
                >{{ component.title }}</router-link
              >
            </h2>
            <div class="flex items-center space-x-5">
              <Switch
                v-model="component.dark"
                :class="
                  component.dark
                    ? 'bg-gray-700'
                    : 'bg-gray-200 dark:bg-gray-600'
                "
                class="relative inline-flex items-center h-6 rounded-full w-11"
              >
                <span class="sr-only">Enable dark theme on component</span>
                <span
                  :class="component.dark ? 'translate-x-6' : 'translate-x-1'"
                  class="inline-block w-4 h-4 transform bg-white rounded-full"
                />
              </Switch>
              <TabList class="p-0.5 rounded-lg flex bg-tab hover:bg-tab-hover">
                <Tab v-slot="{ selected }" as="template">
                  <button class="tab__button">
                    <span
                      class="tab__label"
                      :class="{
                        'tab__label-selected': selected,
                      }"
                    >
                      <EyeIcon
                        aria-hidden="true"
                        class="lg:mr-2 w-5"
                        :class="
                          selected
                            ? 'text-teal-500'
                            : 'text-gray-500 group-hover:text-gray-900'
                        "
                      />
                      Preview
                    </span>
                  </button>
                </Tab>
                <Tab v-slot="{ selected }" as="template">
                  <button class="tab__button">
                    <span
                      class="tab__label"
                      :class="{
                        'tab__label-selected': selected,
                      }"
                    >
                      <svg
                        aria-hidden="true"
                        width="20"
                        height="20"
                        fill="none"
                        class="lg:mr-2"
                        :class="
                          selected
                            ? 'text-teal-500'
                            : 'text-gray-500 group-hover:text-gray-900'
                        "
                      >
                        <path
                          d="M13.75 6.75l3.5 3.25-3.5 3.25M6.25 13.25L2.75 10l3.5-3.25"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                      Code
                    </span>
                  </button>
                </Tab>
              </TabList>
              <button
                type="button"
                class="
                  hidden
                  sm:flex sm:items-center sm:justify-center
                  relative
                  w-9
                  h-9
                  rounded-lg
                  focus:outline-none
                  focus-visible:ring-2 focus-visible:ring-teal-500
                  text-gray-400
                  hover:text-gray-600
                  group
                  ml-2.5
                "
                :style="component.copied ? 'color:#06B6D4' : ''"
                @click="component.copyCode"
                style=""
              >
                <span class="sr-only">Copy code</span>
                <span
                  v-if="component.copied"
                  class="
                    absolute
                    inset-x-0
                    bottom-full
                    mb-2.5
                    flex
                    justify-center
                  "
                  style="display: none"
                >
                  <span
                    class="
                      bg-gray-900
                      text-white
                      rounded-md
                      text-[0.625rem]
                      leading-4
                      tracking-wide
                      font-semibold
                      uppercase
                      py-1
                      px-3
                      filter
                      drop-shadow-md
                    "
                  >
                    <svg
                      aria-hidden="true"
                      width="16"
                      height="6"
                      viewBox="0 0 16 6"
                      class="
                        text-gray-900
                        absolute
                        top-full
                        left-1/2
                        -mt-px
                        -ml-2
                      "
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M15 0H1V1.00366V1.00366V1.00371H1.01672C2.72058 1.0147 4.24225 2.74704 5.42685 4.72928C6.42941 6.40691 9.57154 6.4069 10.5741 4.72926C11.7587 2.74703 13.2803 1.0147 14.9841 1.00371H15V0Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                    Copied!
                  </span>
                </span>
                <svg
                  aria-hidden="true"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  class="
                    stroke-current
                    transform
                    group-hover:rotate-[-4deg]
                    transition
                  "
                  :style="component.copied ? '--tw-rotate:-8deg;' : ''"
                  style=""
                >
                  <path
                    d="M12.9975 10.7499L11.7475 10.7499C10.6429 10.7499 9.74747 11.6453 9.74747 12.7499L9.74747 21.2499C9.74747 22.3544 10.6429 23.2499 11.7475 23.2499L20.2475 23.2499C21.352 23.2499 22.2475 22.3544 22.2475 21.2499L22.2475 12.7499C22.2475 11.6453 21.352 10.7499 20.2475 10.7499L18.9975 10.7499"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M17.9975 12.2499L13.9975 12.2499C13.4452 12.2499 12.9975 11.8022 12.9975 11.2499L12.9975 9.74988C12.9975 9.19759 13.4452 8.74988 13.9975 8.74988L17.9975 8.74988C18.5498 8.74988 18.9975 9.19759 18.9975 9.74988L18.9975 11.2499C18.9975 11.8022 18.5498 12.2499 17.9975 12.2499Z"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M13.7475 16.2499L18.2475 16.2499"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M13.7475 19.2499L18.2475 19.2499"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <g
                    :class="[component.copied ? '' : 'opacity-0']"
                    class="opacity-0 transition-opacity"
                  >
                    <path
                      d="M15.9975 5.99988L15.9975 3.99988"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M19.9975 5.99988L20.9975 4.99988"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M11.9975 5.99988L10.9975 4.99988"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </g>
                </svg>
              </button>
            </div>
          </div>
          <TabPanels>
            <!-- preview tab -->
            <TabPanel>
              <div
                class="
                  relative
                  rounded-lg
                  overflow-hidden
                  shadow-sm
                  ring-1 ring-panel ring-opacity-5
                "
              >
                <div class="relative" style="width: 100%">
                  <RenderToIFrame
                    :css="css"
                    :theme="component.dark ? 'dark' : 'light'"
                    class="pr-5"
                  >
                    <component v-bind:is="component.name" />
                  </RenderToIFrame>
                  <div
                    v-drag="handleDrag"
                    class="
                      absolute
                      right-0
                      top-0
                      bottom-0
                      w-5
                      bg-gray-100
                      dark:bg-gray-600
                      flex
                      items-center
                      justify-center
                    "
                    style="cursor: e-resize"
                  >
                    <svg
                      aria-hidden="true"
                      class="
                        h-4
                        w-4
                        text-gray-600
                        dark:text-gray-200
                        pointer-events-none
                      "
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M8 5h2v14H8zM14 5h2v14h-2z"></path>
                    </svg>
                  </div>
                </div>
              </div>
            </TabPanel>

            <!-- code tab -->
            <TabPanel>
              <ssh-pre
                language="html-vue"
                dark
                class="overflow-auto"
                style="max-height: 55rem"
              >
                {{ component.code }}
              </ssh-pre>
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import componentData from '@/scripts/components';
import RenderToIFrame from '@/components/iframe.js';
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue';
import SshPre from 'simple-syntax-highlighter';
import 'simple-syntax-highlighter/dist/sshpre.css';
import { useClipboard } from '@vueuse/core';
import { ref } from 'vue';
import { EyeIcon } from '@heroicons/vue/outline';
import { Switch } from '@headlessui/vue';

export default {
  name: 'components',
  components: {
    RenderToIFrame,
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    SshPre,
    EyeIcon,
    Switch,
  },
  data() {
    return {
      css: '',
      category: null,
      section: null,
      element: null,
      components: [],
      copied: false,
      initialized: true,
    };
  },
  created() {
    const params = this.$route.params;
    this.category = params.category;
    this.section = params.section;
    this.element = params.element;

    const category = componentData.data.categories.find(
      (cat) => this.toSlug(cat.name) === this.category
    );

    const section = category.sections.find(
      (sec) => this.toSlug(sec.name) === this.section
    );

    const components = section.elements.find(
      (el) => this.toSlug(el.name) === this.element
    )?.components;

    components.forEach((component) => {
      const compName = this.toSlug(component.name.replace('.vue', ''));
      this.$options.components[compName] = defineAsyncComponent(() =>
        import('/src/' + component.path)
      );

      const source = ref(component.code);
      const { text, copy, copied, isSupported } = useClipboard({ source });

      this.components.push({
        name: compName,
        title: this.toHeading(component.name.replace('.vue', '')),
        code: component.code,
        copyCode: () => {
          const clipboardData =
            event.clipboardData ||
            window.clipboardData ||
            event.originalEvent?.clipboardData ||
            navigator.clipboard;

          clipboardData.writeText(component.code);
          copy();
        },
        text,
        copied,
        isSupported,
        dark: false,
      });
    });
  },
  mounted() {
    setInterval(() => {
      const iframes = document.getElementsByTagName('iframe');
      for (let i = 0; i < iframes.length; i++) {
        const iframe = iframes[i];
        var body = iframe.contentWindow.document.body;

        const height = body.firstChild.offsetHeight;

        iframe.style.height = `${height}px`;
      }

      this.getCss();
    }, 500);
  },
  methods: {
    handleDrag(e) {
      const parent = e._dragTarget.parentElement;
      const grandParent = parent.parentElement;
      const delta = e.delta;
      parent.style.width = `${parent.offsetWidth + delta[0]}px`;

      if (parent.offsetWidth < 365) parent.style.width = '365px';
      if (parent.offsetWidth > grandParent.offsetWidth)
        parent.style.width = `${grandParent.offsetWidth}px`;
    },
    toSlug(str) {
      return str
        .toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, '');
    },
    toHeading(str, capitalizeAll = true) {
      str = str.replace(/_/g, ' ').replace(/-/g, ' ');
      return capitalizeAll
        ? str.replace(/\b\w/g, (l) => l.toUpperCase())
        : str.charAt(0).toUpperCase() + str.slice(1);
    },
    getCss() {
      let css = [];

      for (let sheeti = 0; sheeti < document.styleSheets.length; sheeti++) {
        let sheet = document.styleSheets[sheeti];
        let rules = 'cssRules' in sheet ? sheet.cssRules : sheet.rules;
        for (let rulei = 0; rulei < rules.length; rulei++) {
          let rule = rules[rulei];
          if ('cssText' in rule) css.push(rule.cssText);
          else
            css.push(rule.selectorText + ' {\n' + rule.style.cssText + '\n}\n');
        }
      }

      this.css = css.join('\n');
    },
  },
};
</script>

<style lang="scss" scoped>
iframe {
  width: 100%;
}

.tab__button {
  @apply flex focus-visible:ring-2 focus-visible:ring-teal-500 focus-visible:ring-offset-2 rounded-md focus:outline-none focus-visible:ring-offset-gray-100;
}

.tab__label {
  @apply p-1.5 lg:pl-2.5 lg:pr-3.5 rounded-md flex items-center text-sm font-medium;
}

.tab__label-selected {
  @apply bg-tab-active shadow-sm ring-1 ring-black ring-opacity-5;
}
</style>
