import { h, ref, createApp, onMounted, onBeforeUpdate, watchEffect } from 'vue';

export default {
  name: 'RenderToIFrame',
  props: {
    css: {
      type: String,
      default: '',
    },
    theme: {
      type: String,
      default: '',
    },
  },
  setup(props, { slots }) {
    const iframeRef = ref(null);
    const iframeBody = ref(null);
    const iframeHead = ref(null);
    const iframeStyle = ref(null);
    let iframeApp = null;

    onMounted(() => {
      iframeBody.value = iframeRef.value.contentDocument.body;
      iframeHead.value = iframeRef.value.contentDocument.head;
      const el = document.createElement('div');
      iframeBody.value.appendChild(el);
      iframeStyle.value = document.createElement('style');
      iframeStyle.value.innerHTML = props.css;
      iframeHead.value.appendChild(iframeStyle.value);
      iframeBody.value.classList = props.theme;

      watchEffect(() => {
        iframeBody.value.classList = props.theme;
      });

      iframeApp = createApp({
        name: 'iframeRender',
        setup() {
          return () => slots.default();
        },
      }).mount(el);
    });
    onBeforeUpdate(() => {
      if (!iframeApp || !iframeRef.value) {
        return;
      }
      if (props.css) {
        iframeStyle.value.innerHTML = props.css;
      }
    });
    return () => h('iframe', { ref: iframeRef });
  },
};
